<template>
  <div>
    <v-card-title>Checklisten</v-card-title>

    <v-dialog v-model="addSurveyDialog" max-width="800">
      <v-card>
        <v-card-title>Schulung erstellen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-text-field
            label="Name"
            v-model="addSurveyDialogName"
          >
          </v-text-field>
          </v-col>
          <v-col cols="6">
          <v-text-field
            label="Beschreibung"
            v-model="addSurveyDialogDescription"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addSurveyDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addSurveyDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeSurveyDialog" max-width="800">
      <v-card>
        <v-card-title>Schulung entfernen</v-card-title>
        <v-card-text v-if="removeSurveyDialogSurvey != null">
          {{ removeSurveyDialogSurvey.name }}
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeSurveyDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeSurveyDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editQuestionDialog" max-width="800">
      <v-card v-if="editQuestionDialog">
        <v-card-title>Frage bearbeiten</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-text-field
            label="Text"
            v-model="editQuestionDialogQuestion.text"
          >
          </v-text-field>
          <v-textarea
            label="Beschreibung"
            v-model="editQuestionDialogQuestion.description"
            rows="3"
          ></v-textarea>
          </v-col>
          <v-col cols="6">
            <v-radio-group v-model="editQuestionDialogQuestion.questionType">
              <v-radio label="Keine Eingabe" value="text"></v-radio>
              <v-radio label="Texteingabe" value="textInput"></v-radio>
              <v-radio label="Auswahl" value="singleChoice"></v-radio>
              <v-radio label="Unterschrift" value="signature"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="editQuestionDialogQuestion.questionType === 'singleChoice'"
            cols="12">
            <v-radio-group
              v-model="editQuestionDialogCorrectOptionId"
            >
              <v-radio
                v-for="option in sort(optionsByQuestionId(editQuestionDialogQuestion.id))"
                :key="option.id"
                :label="option.text"
                :value="option.id"
              >
                <template v-slot:label>
                  <v-text-field
                    v-model="option.text"
                  ></v-text-field>
                  <v-btn
                    v-if="!positionIsFirst(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                    icon
                    small
                    @click.stop="positionUp(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn>
                  <v-btn
                    v-if="!positionIsLast(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                    icon
                    small
                    @click.stop="positionDown(optionsByQuestionId(editQuestionDialogQuestion.id), option)"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn>
                  <v-btn
                    icon
                    small
                    @click.stop="removeOptionDialogShow(option)"
                  ><v-icon>mdi-delete</v-icon></v-btn>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="editQuestionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="editQuestionDialogQuestion.questionType === 'singleChoice'"
            @click="addOption">Option hinzufügen</v-btn>
          <v-btn color="primary" @click="editQuestionDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeQuestionDialog" max-width="800">
      <v-card>
        <v-card-title>Frage entfernen</v-card-title>
        <v-card-text v-if="removeQuestionDialogQuestion != null">
          Frage "{{ removeQuestionDialogQuestion.text }}"
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeQuestionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeQuestionDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeOptionDialog" max-width="800">
      <v-card>
        <v-card-title>Option entfernen</v-card-title>
        <v-card-text v-if="removeOptionDialogOption != null">
          Option "{{ removeOptionDialogOption.text }}"
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeOptionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeOptionDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="surveys"
          :search="search"
          v-model="selected"
          single-select
          mobile-breakpoint="0"
          @click:row="clickRow"
          sort-by="name"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click="addSurveyDialog = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Schulung erstellen</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  icon
                  color="primary"
                  v-bind="attrs" v-on="on"
                  @click.stop="duplicateSurvey(item)"
                >
                  <v-icon>mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <span>duplizieren</span>
            </v-tooltip>
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  icon
                  color="primary"
                  v-bind="attrs" v-on="on"
                  @click.stop="removeSurveyDialogSurvey = item; removeSurveyDialog = true"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>entfernen</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      
      <v-col
        md="6"
        cols="12"
      >
        <v-card-title>{{ selectedSurvey.name }}</v-card-title>
          <v-text-field
            v-if="selected.length > 0"
            class="mx-4"
            label="Name"
            v-model="selectedSurvey.name"
            :outlined="editSurveyName"
            :readonly="!editSurveyName"
            :prepend-icon="editSurveyName ? 'mdi-close' : ''"
            @click:prepend="editSurveyName = false"
            :append-icon="editSurveyName ? 'mdi-send' : 'mdi-pencil'"
            @click:append="saveSurvey(); editSurveyName = !editSurveyName"
          ></v-text-field>
          
          <v-text-field
            v-if="selected.length > 0"
            class="mx-4"
            label="Beschreibung"
            v-model="selectedSurvey.description"
            :outlined="editSurveyDescription"
            :readonly="!editSurveyDescription"
            :prepend-icon="editSurveyDescription ? 'mdi-close' : ''"
            @click:prepend="editSurveyDescription = false"
            :append-icon="editSurveyDescription ? 'mdi-send' : 'mdi-pencil'"
            @click:append="saveSurvey(); editSurveyDescription = !editSurveyDescription"
          ></v-text-field>
        
        <v-container
          v-for="item in sort(questionsBySurveyId(selectedSurvey.id))"
          :key="item.id"
          class="px-0"
        >
          <v-container>
            <v-card-title>{{ item.text }}<v-spacer></v-spacer>
              <v-btn
                v-if="!positionIsFirst(questionsBySurveyId(selectedSurvey.id), item)"
                small fab color="primary"
                @click.stop="questionPositionUp(questionsBySurveyId(selectedSurvey.id), item)"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
              <v-btn
                v-if="!positionIsLast(questionsBySurveyId(selectedSurvey.id), item)"
                small fab color="primary"
                @click.stop="questionPositionDown(questionsBySurveyId(selectedSurvey.id), item)">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn
                small fab color="primary"
                @click.stop="removeQuestionDialogShow(item)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn
                small fab color="primary"
                @click.stop="editQuestionDialogShow(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle align="left">{{ item.description }}
              <span v-if="item.questionType === 'text'" style="font-style: italic"><br/>Keine Eingabe</span>
              <span v-else-if="item.questionType === 'textInput'" style="font-style: italic"><br/>Texteingabe</span>
              <span v-else-if="item.questionType === 'signature'" style="font-style: italic"><br/>Unterschrift</span>
            </v-card-subtitle>

            <v-card-text v-if="item.questionType === 'singleChoice'">
              <v-radio-group :value="correctIdFromArray(optionsByQuestionId(item.id))">
                <v-radio
                  v-for="option in sort(optionsByQuestionId(item.id))"
                  :key="option.id"
                  :label="option.text"
                  :value="option.id"
                  readonly
                ></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-container>
        </v-container>
        
        <v-container v-if="selected.length !== 0">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              fab
              color="primary"
              @click="addQuestion"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Actions, AuthGetters, AuthPath, SurveysActions, SurveysGetters, SurveysPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'Surveys',
  
  components: {
  },

  mounted: function() {
    this[SurveysActions.REFRESH]()
    this[SurveysActions.REFRESH_QUESTIONS]()
  },

  data: () => {
    return {
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' },
      ],
      
      search: '',
      selected: [],
      noneSelected: {
          id: -1,
          name: 'Keine Schulung ausgewählt',
          description: '',
          status: 'created',
          questions: []
      },
      addSurveyDialog: false,
      addSurveyDialogName: '',
      addSurveyDialogDescription: '',
      removeSurveyDialog: false,
      removeSurveyDialogSurvey: null,
      editSurveyName: false,
      editSurveyDescription: false,
      editSurveyContent: false,
      editQuestionDialog: false,
      editQuestionDialogQuestion: null,
      editQuestionDialogCorrectOptionId: -1,
      removeQuestionDialog: false,
      removeQuestionDialogQuestion: null,
      removeOptionDialog: false,
      removeOptionDialogOption: null
    }
  },

  computed: {
    selectedSurvey: function() {
      if(this.selected.length <= 0) {
        return this.noneSelected;
      }

      this[SurveysActions.REFRESH_QUESTIONS]();
      this[SurveysActions.REFRESH_OPTIONS]();
      return this.selected[0];
    },

    ...mapState(SurveysPath, [ 'surveys' ]),

    ...mapGetters(SurveysPath, {
      questionsBySurveyId: SurveysGetters.QUESTIONS_BY_SURVEY_ID,
      optionsByQuestionId: SurveysGetters.OPTIONS_BY_QUESTION_ID
    }),

    ...mapGetters(AuthPath, { isAdmin: AuthGetters.IS_ADMIN })
  },

  methods: {
    clickRow: function(item, row) {
      if(this.selected.some(e => e.id === item.id)) {
        row.select(false);
      } else {
        row.select(true);
      }
    },

    positionIsFirst: function(array, item) {
      return !array.some(e => e.position < item.position)
    },

    positionIsLast: function(array, item) {
      return !array.some(e => e.position > item.position)
    },

    sort: function(array) {
      return array.slice().sort((a, b) => a.position - b.position);
    },

    correctIdFromArray: function(array) {
      if (array.length <= 0) {
        return -1
      }
      const correct = array.find(e => e.correct)
      if (!correct) {
        return -1
      }
      return correct.id
    },

    questionPositionUp: function(array, item) {
      this.positionUp(array, item, this[SurveysActions.EDIT_QUESTION])
    },

    questionPositionDown: function(array, item) {
      this.positionDown(array, item, this[SurveysActions.EDIT_QUESTION])
    },

    positionUp: function(array, item, saveAction = null) {
      const sorted = this.sort(array)
      const index = sorted.findIndex(e => e.id == item.id)
      if (index < 1) {
        return;
      }
      const prevItem = sorted[index - 1]
      this.positionSwitch(item, prevItem)
      if (saveAction !== null) {
        saveAction(array.find(e => e.id == prevItem.id))
        saveAction(array.find(e => e.id == item.id))
      }
    },

    positionDown: function(array, item, saveAction = null) {
      const sorted = this.sort(array)
      const index = sorted.findIndex(e => e.id == item.id)
      if (index >= (array.length - 1)) {
        return;
      }
      const prevItem = sorted[index + 1]
      this.positionSwitch(item, prevItem)
      saveAction(array.find(e => e.id == prevItem.id))
      saveAction(array.find(e => e.id == item.id))
    },

    positionSwitch: function(a, b) {
      const aPosition = a.position;
      const bPosition = b.position;
      b.position = aPosition;
      a.position = bPosition;
    },

    addSurveyDialogSave: function() {
      if (this.isAdmin) {
        const survey = {
          name: this.addSurveyDialogName,
          description: this.addSurveyDialogDescription,
          content: '',
          status: 'created'
        }
        this[SurveysActions.ADD_SURVEY](survey)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Checkliste hinzugefügt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Checkliste nicht hinzugefügt'))
      }
      this.addSurveyDialog = false
      this.addSurveyDialogName = ''
      this.addSurveyDialogDescription = ''
    },

    removeSurveyDialogSave: function() {
      if (this.isAdmin && this.removeSurveyDialogSurvey) {
        this[SurveysActions.REMOVE_SURVEY](this.removeSurveyDialogSurvey.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Schulung entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht entfernt'))
      }
      this.removeSurveyDialog = false
      this.removeSurveyDialogSurvey = null
    },

    addQuestion: function() {
      const questions = this.sort(this.questionsBySurveyId(this.selectedSurvey.id))
      let position = 1
      if (questions.length > 0) {
        position = questions[questions.length - 1].position + 1
      }
      const question = {
        surveyId: this.selectedSurvey.id,
        position: position,
        text: 'Neue Frage',
        description: '',
        questionType: 'text'
      }
      this[SurveysActions.ADD_QUESTION](question)
    },

    addOption: function() {
      const options = this.sort(this.optionsByQuestionId(this.editQuestionDialogQuestion.id))
      const position = options.length == 0 ? 0 : options[options.length - 1].position + 1
      const option = {
        surveyQuestionId: this.editQuestionDialogQuestion.id,
        position: position,
        text: 'Neue Option',
        correct: false
      }
      this[SurveysActions.ADD_OPTION](option)
    },

    editQuestionDialogShow: function(item) {
      this.editQuestionDialogQuestion = item
      this.editQuestionDialogCorrectOptionId = this.correctIdFromArray(this.optionsByQuestionId(item.id))
      this.editQuestionDialog = true
    },

    editQuestionDialogSave: function() {
      if (this.isAdmin && this.editQuestionDialog) {
        this[SurveysActions.EDIT_QUESTION](this.editQuestionDialogQuestion)
        if (this.editQuestionDialogQuestion.questionType === 'singleChoice') {
          for (const option of this.optionsByQuestionId(this.editQuestionDialogQuestion.id)) {
            option.correct = this.editQuestionDialogCorrectOptionId == option.id
            this[SurveysActions.EDIT_OPTION](option)
          }
        } else {
          for (const option of this.optionsByQuestionId(this.editQuestionDialogQuestion.id)) {
            this[SurveysActions.REMOVE_OPTION](option.id)
          }
          this[SurveysActions.ADD_OPTION]({
            surveyQuestionId: this.editQuestionDialogQuestion.id,
            position: 0,
            text: '',
            correct: 0
          })
        }
        this.editQuestionDialog = false
        this.editQuestionDialogQuestion = null
      }
    },

    removeQuestionDialogShow: function(item) {
      this.removeQuestionDialogQuestion = item
      this.removeQuestionDialog = true
    },

    removeQuestionDialogSave: function() {
      if (this.isAdmin && this.removeQuestionDialogQuestion) {
        this[SurveysActions.REMOVE_QUESTION](this.removeQuestionDialogQuestion.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Frage entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Frage nicht entfernt'))
      }
      this.removeQuestionDialog = false
      this.removeQuestionDialogQuestion = null
    },

    removeOptionDialogShow: function(item) {
      this.removeOptionDialogOption = item
      this.removeOptionDialog = true
    },

    removeOptionDialogSave: function() {
      if (this.isAdmin && this.removeOptionDialogOption) {
        this[SurveysActions.REMOVE_OPTION](this.removeOptionDialogOption.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Option entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Option nicht entfernt'))
      }
      this.removeOptionDialog = false
      this.removeOptionDialogOption = null
    },

    duplicateSurvey: function(originalSurvey) {
      if (this.isAdmin) {
        this[SurveysActions.DUPLICATE_SURVEY](originalSurvey.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Checkliste dupliziert'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Checkliste nicht dupliziert'))
      }
    },

    saveSurvey: function() {
      if (this.selectedSurvey !== null &&
         (this.editSurveyName || this.editSurveyDescription || this.editSurveyContent)) {
        this[SurveysActions.EDIT_SURVEY](this.selectedSurvey)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Checkliste gespeichert.'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Checkliste nicht gespeichert.'))
      }
    },

    ...mapActions(SurveysPath, [
      SurveysActions.REFRESH,
      SurveysActions.REFRESH_QUESTIONS,
      SurveysActions.REFRESH_OPTIONS,
      SurveysActions.DUPLICATE_SURVEY,
      SurveysActions.EDIT_SURVEY,
      SurveysActions.EDIT_QUESTION,
      SurveysActions.EDIT_OPTION,
      SurveysActions.ADD_SURVEY,
      SurveysActions.ADD_QUESTION,
      SurveysActions.ADD_OPTION,
      SurveysActions.REMOVE_SURVEY,
      SurveysActions.REMOVE_QUESTION,
      SurveysActions.REMOVE_OPTION
    ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_INFO,
      Actions.NOTIFICATION_SHOW_ERROR
    ])
  }
}
</script>
