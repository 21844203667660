<template>
  <div>
    <v-img
      :src="require('../assets/logo.png')"
      class="my-12 mx-8"
      contain
      height="120"
    />
    <v-row>
      <v-col md="4" cols="1">
      </v-col>
      <v-col md="4" cols="10">
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab>Mitarbeiter</v-tab>
          <v-tab>Admin</v-tab>
        </v-tabs>
      </v-col>
      <v-col md="4" cols="1">
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4" cols="1">
      </v-col>
      <v-col md="4" cols="10">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-form
              @submit="loginUser"
            >
              <v-text-field
                v-model="token"
                label="Token"
              ></v-text-field>
              <v-text-field
                v-model="pin"
                label="Pin"
                type="password"
              ></v-text-field>
              <v-btn
                color="primary"
                type="submit"
                :disabled="!loginButtonEnabled"
              >Login</v-btn>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <v-form
              @submit="loginAdmin"
            >
              <v-text-field
                v-model="username"
                label="E-Mail"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Passwort"
                type="password"
              ></v-text-field>
              <v-btn
                color="primary"
                type="submit"
                :disabled="!loginButtonEnabled"
              >Login</v-btn>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
        <v-alert
          v-if="notificationShow"
          dense
          border="top"
          :color="notificationColor"
          style="color: white"
          class="mt-4"
        >{{ notificationText }}</v-alert>
      </v-col>
      <v-col md="4" cols="1">
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import { AuthPath, AuthActions, Actions, Getters, NOTIFICATION_TYPE } from '../store/definitions'

export default {
  name: 'Login',
  components: {
  },

  mounted: function() {
    const query = this.$route.query;
    if (query != undefined) {
      if (query.token != undefined) {
        this.token = query.token;
        this.tab = 0;
      } else if (query.username != undefined) {
        this.username = query.username;
        this.tab = 1;
      }
    }
  },

  data: () => {
    return {
      tab: 1,
      username: '',
      password: '',
      token: '',
      pin: '',
      loginButtonEnabled: true
    }
  },

  computed: {
    notificationColor: function() {
      const type = this.notificationType
      return type == NOTIFICATION_TYPE.INFO ? 'green' : 'red'
    },

    ...mapGetters({
      notificationShow: Getters.NOTIFICATION_SHOW,
      notificationType: Getters.NOTIFICATION_TYPE,
      notificationText: Getters.NOTIFICATION_TEXT
    })
  },

  methods: {
    loginAdmin: function(event) {
      event.preventDefault()
      const { username, password } = this
      this.loginButtonEnabled = false
      this[AuthActions.REQUEST]({ username, password })
      .then(() => this.$router.push('/'))
      .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Login-Fehler'))
      .finally(() => this.loginButtonEnabled = true)
      this.password = ''
    },
    
    loginUser: function(event) {
      event.preventDefault()
      const { token, pin } = this;
      this[AuthActions.REQUEST]({ token, pin })
      .then(() => this.$router.push('/'))
      .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Login-Fehler'))
      .finally(() => this.loginButtonEnabled = true)
      this.password = ''
    },

    ...mapActions(AuthPath, [ AuthActions.REQUEST ]),
    ...mapActions([ Actions.NOTIFICATION_SHOW_ERROR ])
  }
}
</script>
