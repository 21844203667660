<template>
  <div>
    <v-card-title>Mitarbeiter</v-card-title>

    <v-dialog v-model="newUserDialog" max-width="800">
      <v-card>
        <v-card-title>Neuer Mitarbeiter</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
            <v-text-field v-model="newUserDialogName" label="Name"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="newUserDialogFirstName" label="Vorname"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="newUserDialogEmail" label="E-Mail"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="newUserDialogPhone" label="Telefonnummer"></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="newUserDialogHide">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="newUserDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeUserDialog" max-width="800">
      <v-card>
        <v-card-title>Mitarbeiter entfernen</v-card-title>
        <v-card-text v-if="removeUserDialogUser != null">
          {{ removeUserDialogUser.name }} {{ removeUserDialogUser.firstName }}
          wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeUserDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="removeUserDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editUserDialog" max-width="800">
      <v-card v-if="editUserDialogUser !== null">
        <v-card-title>
          Mitarbeiter 
          {{ editUserDialogUser.name }} {{ editUserDialogUser.firstName }}
          bearbeiten</v-card-title>
        <v-row class="mx-2 mb-2">
          <v-col cols="6">
            <v-text-field
              v-model="editUserDialogUser.token"
              label="Token"
              readonly
            >
            </v-text-field>
            <v-text-field
              v-model="editUserDialogUserPin"
              :label="editUserDialogUser.otp === null ? 'Pin' : 'Einladungs-Pin'"
              readonly
              append-icon="mdi-refresh"
              @click:append.stop="resetUserPinDialog = true"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <qrcode-vue :value="editUserDialogLink" size="120" level="H"></qrcode-vue>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="editUserDialogUserStatus"
              label="Status"
              readonly
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="editUserDialogLink"
              :label="editUserDialogUser.otp === null ? 'Login-Link' : 'Einladungs-Link'"
              readonly
              append-icon="mdi-content-copy"
              @click:append="editUserDialogClickCopy"
              append-outer-icon="mdi-share-variant"
              @click:append-outer="editUserDialogClickShare"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resetUserPinDialog" max-width="800">
      <v-card>
        <v-card-title>PIN zur&uuml;cksetzen?</v-card-title>
        <v-card-text v-if="editUserDialogUser != null">
          Der Mitarbeiter {{ editUserDialogUser.name }} {{ editUserDialogUser.firstName }}
          muss sich neu registrieren. Das kann nicht r&uuml;ckg&auml;ngig gemacht werden.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="resetUserPinDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="resetUserPinDialogSave">PIN zur&uuml;cksetzen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      v-model="selected"
      sort-by="name"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.name"
          persistent
          large
          @save="editUser(item.id)"
          save-text="Speichern"
          cancel-text="Abbrechen"
        >
          {{ item.name }}
          <template v-slot:input>
            <v-text-field
              v-model="item.name"
              label="Bearbeiten"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      
      <template v-slot:[`item.firstName`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.firstName"
          persistent
          large
          @save="editUser(item.id)"
          save-text="Speichern"
          cancel-text="Abbrechen"
        >
          {{ item.firstName }}
          <template v-slot:input>
            <v-text-field
              v-model="item.firstName"
              label="Bearbeiten"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      
      <template v-slot:[`item.phone`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.phone"
          persistent
          large
          @save="editUser(item.id)"
          save-text="Speichern"
          cancel-text="Abbrechen"
        >
          {{ item.phone }}
          <v-btn
            v-if="item.phone !== ''"
            icon
            small
            @click.stop="call(item.phone)"
          ><v-icon>mdi-phone</v-icon></v-btn>
          <template v-slot:input>
            <v-text-field
              v-model="item.phone"
              label="Bearbeiten"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      
      <template v-slot:[`item.email`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.email"
          persistent
          large
          @save="editUser(item.id)"
          save-text="Speichern"
          cancel-text="Abbrechen"
        >
          {{ item.email }}
          <v-btn
            v-if="item.email !== ''"
            icon
            small
            @click.stop="email(item.email)"
          ><v-icon>mdi-email</v-icon></v-btn>
          <template v-slot:input>
            <v-text-field
              v-model="item.email"
              label="Bearbeiten"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.edited`]="{ item }">
        <v-tooltip
          v-if="edited.includes(item.id)"
          left
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              v-bind="attrs" v-on="on"
              @click.stop="editUserAbort(item.id)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>verwerfen</span>
        </v-tooltip>

        <v-tooltip
          v-if="edited.includes(item.id)"
          left
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              v-bind="attrs" v-on="on"
              @click.stop="saveUser(item.id)"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>speichern</span>
        </v-tooltip>
      </template>
      
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              icon
              color="primary"
              v-bind="attrs" v-on="on"
              @click="editUserDialogUser = item; editUserDialog = true"
            >
              <v-icon>mdi-share-variant-outline</v-icon>
            </v-btn>
          </template>
          <span>Mitarbeiter bearbeiten</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              icon
              color="primary"
              v-bind="attrs" v-on="on"
              @click="removeUserDialogUser = item; removeUserDialog = true"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Mitarbeiter entfernen</span>
        </v-tooltip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-tooltip left v-if="showInviteSelected">
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                fab
                small
                color="primary"
                @click="inviteSelectedUsers"
              >
                <v-icon>mdi-email-send-outline</v-icon>
              </v-btn>
            </template>
            <span>markierte einladen</span>
          </v-tooltip>

          <v-tooltip left v-if="showUninviteSelected">
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                fab
                small
                color="primary"
                @click="revokeSelectedInvitations"
              >
                <v-icon>mdi-email-off-outline</v-icon>
              </v-btn>
            </template>
            <span>markierte sperren</span>
          </v-tooltip>

          <v-tooltip left v-if="showBlockSelected">
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                fab
                small
                color="primary"
                @click="blockSelectedUsers"
              >
                <v-icon>mdi-account-cancel-outline</v-icon>
              </v-btn>
            </template>
            <span>markierte sperren</span>
          </v-tooltip>

          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="primary"
                v-bind="attrs" v-on="on"
                @click="newUserDialogShow"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Neuer Mitarbeiter</span>
          </v-tooltip>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// @ is an alias to /src
import QrcodeVue from 'qrcode.vue'
import { mapActions, mapGetters } from 'vuex';
import { Actions, UsersActions, UsersGetters, UsersPath } from '../store/definitions';

export default {
  name: 'Users',
  
  components: {
    QrcodeVue
  },

  mounted: function() {
    this[UsersActions.REFRESH]();
  },

  data: () => {
    return {
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Vorname', align: 'start', sortable: true, value: 'firstName' },
        { text: 'E-Mail', align: 'end', sortable: false, value: 'email' },
        { text: 'Telefonnummer', align: 'end', sortable: true, value: 'phone' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' },
      ],
      pinPlaceholder: '****************************************',
      search: '',
      selected: [],
      edited: [],
      newUserDialog: false,
      newUserDialogName: '',
      newUserDialogFirstName: '',
      newUserDialogEmail: '',
      newUserDialogPhone: '',
      editUserDialog: false,
      editUserDialogUser: null,
      removeUserDialog: false,
      removeUserDialogUser: null,
      resetUserPinDialog: false
    }
  },

  computed: {
    editUserDialogUserPin: function() {
      const user = this.editUserDialogUser
      if (user.otp === null) {
        return this.pinPlaceholder
      }
      return user.otp
    },

    editUserDialogLink: function() {
      const user = this.editUserDialogUser
      if (user === null) {
        return ''
      }
      const baseUrl = window.location.origin;
      if (user.otp !== null) {
        return `${baseUrl}/register?token=${user.token}&otp=${encodeURIComponent(user.otp)}`
      }
      return `${baseUrl}/login?token=${user.token}`
    },
    editUserDialogUserStatus: function() {
      const user = this.editUserDialogUser
      if (user.otp === null) {
        return 'aktiv'
      }
      return 'erstellt'
    },

    showInviteSelected: function() {
      if(this.selected === null || this.selected === undefined || this.selected.length === 0) {
        return false;
      }
      for(const element of this.selected) {
        if(element.registrationStatus !== 'created') {
          return false;
        }
      }

      return true;
    },
    showUninviteSelected: function() {
      if(this.selected === null || this.selected === undefined || this.selected.length === 0) {
        return false;
      }
      for(const element of this.selected) {
        if(element.registrationStatus !== 'invited') {
          return false;
        }
      }

      return true;
    },
    showBlockSelected: function() {
      if(this.selected === null || this.selected === undefined || this.selected.length === 0) {
        return false;
      }
      for(const element of this.selected) {
        if(element.registrationStatus !== 'registered') {
          return false;
        }
      }

      return true;
    },

    ...mapGetters(UsersPath, { 'users': UsersGetters.USERS })
  },

  methods: {
    newUserDialogShow: function() {
      this.newUserDialogName = '';
      this.newUserDialogFirstName = '';
      this.newUserDialogEmail = '';
      this.newUserDialogPhone = '';
      this.newUserDialog = true;
    },

    newUserDialogHide: function() {
      this.newUserDialog = false;
    },

    newUserDialogSave: function() {
      this.addUser(this.newUserDialogName, this.newUserDialogFirstName, this.newUserDialogEmail, this.newUserDialogPhone);
      this.newUserDialog = false;
    },

    removeUserDialogSave: function() {
      if (this.removeUserDialogUser) {
        this[UsersActions.REMOVE_USER](this.removeUserDialogUser.id)
        .then(() => {
          this[Actions.NOTIFICATION_SHOW_INFO]('Mitarbeiter entfernt')
        })
        .catch(() => {
          this[Actions.NOTIFICATION_SHOW_ERROR]('Mitarbeiter nicht entfernt')
        })
      }
      this.removeProjectUserDialog = false
      this.removeProjectUserDialogUser = null
    },

    resetUserPinDialogSave: function() {
      const userId = this.editUserDialogUser.id
      this[UsersActions.RESET_PIN](userId)
      .then(() => {
        this.resetUserPinDialog = false
      })
      .then(() => {
        this[Actions.NOTIFICATION_SHOW_INFO]('PIN zurückgesetzt')
        this[UsersActions.REFRESH]()
        .then(() => {
          this.editUserDialogUser = this.users.find(u => u.id === userId)
        })
      })
      .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('PIN nicht zurückgesetzt'))
    },

    addUser: function(name, firstName, email, phone) {
      const user = {
        name,
        firstName,
        email,
        phone: phone,
        registrationStatus: 'created'
      };
      this[UsersActions.CREATE](user);
    },

    editUser: function(id) {
      if(!this.edited.includes(id)) {
        let nextEdited = this.edited;
        nextEdited.push(id);
        this.edited = nextEdited;
      }
      this.saveUser(id)
    },

    editUserAbort: function(id) {
      if(this.edited.includes(id)) {
        this.edited = this.edited.filter(e => e != id);
      }
    },

    saveUser: function(id) {
      if(this.edited.includes(id)) {
        const user = this.users.find(u => u.id == id);
        this[UsersActions.EDIT](user)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Mitarbeiter bearbeitet'))
        .finally(() => this[UsersActions.REFRESH]())
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Mitarbeiter nicht bearbeitet'))
        this.editUserAbort(id);
      }
    },

    call: function(phone) {
      window.open('tel:' + phone, '_self');
    },

    email: function(email) {
      window.open('mailto:' + email, '_self');
    },

    inviteSelectedUsers() {
      const selectedUsers = this.selected;
      if(selectedUsers === null || selectedUsers === undefined || !Array.isArray(selectedUsers)) {
        return;
      }

      selectedUsers.forEach(selectedUser => this.inviteUser(selectedUser.id));
    },

    editUserDialogClickCopy: function() {
      navigator.clipboard.writeText(this.editUserDialogLink)
      this[Actions.NOTIFICATION_SHOW_INFO]('Link kopiert')
    },

    editUserDialogClickShare: function() {
      const shareData = {
        title: 'LSH Einladung',
        text: "Einladung",
        url: this.editUserDialogLink
      };

      navigator.share(shareData);
    },

    revokeSelectedInvitations() {
      const selectedUsers = this.selected;
      if(selectedUsers === null || selectedUsers === undefined || !Array.isArray(selectedUsers)) {
        return;
      }

      selectedUsers.forEach(selectedUser => this.revokeInvitation(selectedUser.id));
    },

    revokeInvitation: function(id) {
      if(this.users === null || this.users === undefined || id >= this.users.length) {
        return;
      }
      if(this.users[id].registrationStatus !== 'invited') {
        return;
      }
      this.users[id].registrationStatus = 'created';
    },

    blockSelectedUsers() {
      const selectedUsers = this.selected;
      if(selectedUsers === null || selectedUsers === undefined || !Array.isArray(selectedUsers)) {
        return;
      }

      selectedUsers.forEach(selectedUser => this.blockUser(selectedUser.id));
    },

    blockUser: function(id) {
      if(this.users === null || id >= this.users.length) {
        return;
      }

      if(this.users[id].registrationStatus !== 'registered') {
        return;
      }
      this.users[id].registrationStatus = 'created';
    },

    ...mapActions(UsersPath, [
      UsersActions.CREATE,
      UsersActions.EDIT,
      UsersActions.REFRESH,
      UsersActions.REMOVE_USER,
      UsersActions.RESET_PIN
    ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_ERROR,
      Actions.NOTIFICATION_SHOW_INFO
    ])
  }
}
</script>
