<template>
  <div>
    <v-card-title>{{ project.name }}</v-card-title>
    <v-row class="mx-1">
      <v-col md="3" cols="12" class="py-0 my-0">
        <v-text-field
          label="Straße"
          value="Hauptstraße 123"
          readonly
          :append-icon="isAdmin ? 'mdi-pencil' : 'mdi-map-marker'"
        >
        </v-text-field>
      </v-col>
      <v-col md="3" cols="12" class="py-0 my-0">
        <v-text-field
          label="Ort"
          value="8010 Graz"
          readonly
          :append-icon="isAdmin ? 'mdi-pencil' : 'mdi-map-marker'"
        >
        </v-text-field>
      </v-col>
      <v-col md="3" cols="6" class="py-0 my-0">
        <v-text-field
          label="Erste Hilfe Kasten"
          value="Aufenthaltsraum"
          readonly
          :append-icon="isAdmin ? 'mdi-pencil' : ''"
        >
        </v-text-field>
      </v-col>
      <v-col md="3" cols="6" class="py-0 my-0">
        <v-text-field
          label="Telefon Ansprechperson"
          value="06641234567"
          readonly
          :append-icon="isAdmin ? 'mdi-pencil' : 'mdi-phone'"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-dialog v-model="addProjectUserDialog" max-width="800">
      <v-card>
        <v-card-title>Projektmitarbeiter hinzuf&uuml;gen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-combobox
            label="Mitarbeiter"
            :items="allUsersFormatted"
            v-model="addProjectUserDialogUser"
          >
          </v-combobox>
          </v-col>
          <v-col cols="6">
          <v-combobox
            label="Rolle"
            :items="projectUserRoles"
            v-model="addProjectUserDialogRole"
            item-text="roleName"
          ></v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addProjectUserDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProjectUserDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeProjectUserDialog" max-width="800">
      <v-card>
        <v-card-title>Projektmitarbeiter entfernen</v-card-title>
        <v-card-text v-if="removeProjectUserDialogUser != null">
          {{ removeProjectUserDialogUser.name }} {{ removeProjectUserDialogUser.firstName }} ({{ removeProjectUserDialogUser.role }})
          wirklich aus dem Projekt entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeProjectUserDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeProjectUserDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addProjectSurveyDialog" max-width="800">
      <v-card>
        <v-card-title>Checkliste hinzuf&uuml;gen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
          <v-combobox
            label="Checkliste"
            :items="allSurveysFormatted"
            v-model="addProjectSurveyDialogSurvey"
          >
          </v-combobox>
          </v-col>
          <v-col cols="6">
          <v-text-field
            label="Name in Projekt"
            v-model="addProjectSurveyDialogName"
          >
          </v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addProjectSurveyDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addProjectSurveyDialogSave">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addSurveySubmissionDialog" max-width="800">
      <v-card>
        <v-card-title>Eintrag hinzuf&uuml;gen</v-card-title>
        <v-row class="mx-2">
          <v-col cols="6">
            <v-text-field label="Beschreibung"
              v-model="addSurveySubmissionDialogSurveyDescription"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="Checkliste"
              :items="roleRequirementsByProjectId(project.id)"
              item-text="name"
              v-model="addSurveySubmissionDialogSurvey"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="addSurveySubmissionDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addSurveySubmissionDialogSave">Eintrag hinzuf&uuml;gen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeProjectSurveyDialog" max-width="800">
      <v-card>
        <v-card-title>Eintrag entfernen</v-card-title>
        <v-card-text v-if="removeProjectSurveyDialogSurvey != null">
          Eintrag '{{ removeProjectSurveyDialogSurvey.description }}' von {{ removeProjectSurveyDialogSurvey.author }} ({{ removeProjectSurveyDialogSurvey.datetime }}) wirklich entfernen?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="removeProjectSurveyDialog = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="removeProjectSurveyDialogSave">Entfernen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col md="6" cols="12">
          <v-data-table
            :headers="teamHeaders"
            :items="usersByProjectId(project.id)"
            item-key="projectUserId"
            group-by="role"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title class="pl-0">Mitarbeiter</v-card-title>

                <v-spacer></v-spacer>

                <v-tooltip left v-if="isAdmin">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      v-bind="attrs" v-on="on"
                    >
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Gruppe hinzuf&uuml;gen</span>
                </v-tooltip>

                <v-tooltip left v-if="isAdmin">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      v-bind="attrs" v-on="on"
                      @click="addProjectUserDialog = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Mitarbeiter hinzuf&uuml;gen</span>
                </v-tooltip>
              </v-toolbar>
            </template>

            <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
              <td @click="toggle" :colspan="isAdmin ? headers.length - 1 : headers.length" class="text-left">
                <v-btn
                  small
                  icon
                  :ref="group">
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }}
              </td>
              <td colspan="1" align="right" v-if="isAdmin">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      icon
                      color="primary"
                      v-bind="attrs" v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Gruppe bearbeiten</span>
                </v-tooltip>
              </td>
            </template>

            <template v-slot:[`item.finished`]>
              <v-icon
                color="primary"
              >mdi-circle-outline</v-icon>
            </template>
            
            <template v-slot:[`item.phone`]="{ item }">
              <div class="d-none d-sm-block">{{ item.phone }}
                <v-btn
                  icon
                  small
                  @click.stop="call(item.phone)"
                ><v-icon>mdi-phone</v-icon></v-btn>
              </div>
              <div class="d-sm-none">
                <v-btn
                  icon
                  small
                  @click.stop="call(item.phone)"
                ><v-icon>mdi-phone</v-icon></v-btn>
              </div>
            </template>
      
            <template v-slot:[`item.email`]="{ item }">
              <div class="d-none d-sm-block">{{ item.email }}
                <v-btn
                  icon
                  small
                  @click.stop="email(item.email)"
                ><v-icon>mdi-email</v-icon></v-btn>
              </div>
              <div class="d-sm-none">
                <v-btn
                  icon
                  small
                  @click.stop="email(item.email)"
                ><v-icon>mdi-email</v-icon></v-btn>
              </div>
            </template>
      
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="isAdmin">
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    icon
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click="removeProjectUserDialogUser = item; removeProjectUserDialog = true"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Mitarbeiter entfernen</span>
              </v-tooltip>
            </template>
          </v-data-table>
      </v-col>

      <v-col md="6" cols="12">
          <v-data-table
            mobile-breakpoint="0"
            :items="submissionsByPrrs(roleRequirementsByProjectId(project.id))"
            :headers="surveysHeaders"
            group-by="category"
            @click:row="clickSubmission"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title class="pl-0">Dokumentation</v-card-title>

                <v-spacer></v-spacer>

                <v-tooltip left v-if="isAdmin">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      v-bind="attrs" v-on="on"
                      @click="addProjectSurveyDialog = true"
                    >
                      <v-icon>mdi-file-document-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Dokumenttyp hinzuf&uuml;gen</span>
                </v-tooltip>

                <v-tooltip left v-else>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="primary"
                      v-bind="attrs" v-on="on"
                      @click="addSurveySubmissionDialog = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Eintrag hinzuf&uuml;gen</span>
                </v-tooltip>
              </v-toolbar>
            </template>

            <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
              <td @click="toggle" :colspan="isAdmin ? headers.length - 1 : headers.length" class="text-left">
                <v-btn
                  small
                  icon
                  :ref="group">
                  <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ group }}
              </td>
              <td colspan="1" align="right" v-if="isAdmin">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      icon
                      color="primary"
                      v-bind="attrs" v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Dokumenttyp bearbeiten</span>
                </v-tooltip>
              </td>
            </template>
      
            <template v-slot:[`item.description`]="{ item }">
              <span v-if="item.id !== undefined">{{ item.description }}</span>
              <span v-if="item.id === undefined" style="color: grey">Keine Eintr&auml;ge</span>
            </template>
      
            <template v-slot:[`item.datetime`]="{ item }">
              <div v-if="item.id !== undefined" class="d-none d-sm-block">{{ item.datetime.slice(8,10) }}.{{ item.datetime.slice(5,7) }}.{{ item.datetime.slice(0,4) }} {{ item.datetime.slice(11,16) }}</div>
              <div v-if="item.id !== undefined" class="d-sm-none">{{ item.datetime.slice(8,10) }}.{{ item.datetime.slice(5,7) }}.{{ item.datetime.slice(0,4) }}<br/>{{ item.datetime.slice(11,16) }}</div>
            </template>
      
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="isAdmin && item.id !== undefined">
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    icon
                    color="primary"
                    v-bind="attrs" v-on="on"
                    @click.stop="removeProjectSurveyDialogSurvey = item; removeProjectSurveyDialog = true"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Dokument entfernen</span>
              </v-tooltip>
            </template>
          </v-data-table>
      </v-col>
      
      <v-col md="6" cols="12">
          <v-data-table
            mobile-breakpoint="0"
            :items="unterlagen"
            :headers="unterlagenHeaders"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-card-title class="pl-0">Unterlagen</v-card-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
      
            <template v-slot:[`item.datetime`]="{ item }">
              <div class="d-none d-sm-block">{{ item.datetime.slice(8,10) }}.{{ item.datetime.slice(5,7) }}.{{ item.datetime.slice(0,4) }} {{ item.datetime.slice(11,16) }}</div>
              <div class="d-sm-none">{{ item.datetime.slice(8,10) }}.{{ item.datetime.slice(5,7) }}.{{ item.datetime.slice(0,4) }}<br/>{{ item.datetime.slice(11,16) }}</div>
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Actions, AuthActions, AuthGetters, AuthPath, ProjectsActions, ProjectsGetters, ProjectsPath, SurveysActions, SurveysGetters, SurveysPath, UsersActions, UsersGetters, UsersPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'ProjectDetails',
  
  components: {
  },

  mounted: function() {
    this[AuthActions.REFRESH]();
    this[UsersActions.REFRESH]();
    this[SurveysActions.REFRESH]();
    this[SurveysActions.REFRESH_SUBMISSIONS]();
    this[ProjectsActions.REFRESH_USERS]();
    this[ProjectsActions.REFRESH_ROLEREQUIREMENTS]();
    this[ProjectsActions.REFRESH]();
  },

  data: () => {
    return {
      teamHeadersAdmin: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Vorname', align: 'start', sortable: true, value: 'firstName' },
        { text: 'OK', align: 'start', sortable: true, value: 'finished' },
        { text: 'Rolle', align: 'start', sortable: true, value: 'role' },
        { text: 'E-Mail', align: 'end', sortable: true, value: 'email' },
        { text: 'Telefon', align: 'start', sortable: true, value: 'phone' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
      ],
      teamHeadersUser: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Vorname', align: 'start', sortable: true, value: 'firstName' },
        { text: 'Rolle', align: 'start', sortable: true, value: 'role' },
        { text: 'E-Mail', align: 'end', sortable: true, value: 'email' },
        { text: 'Telefon', align: 'end', sortable: true, value: 'phone' },
      ],
      // TODO: CRUD survey submissions
      surveysHeadersAdmin: [
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Autor', align: 'start', sortable: true, value: 'author' },
        { text: 'Hochgeladen', align: 'end', sortable: true, value: 'datetime' },
        { text: 'Aktionen', align: 'end', sortable: false, value: 'actions' }
      ],
      surveysHeadersUser: [
        { text: 'Beschreibung', align: 'start', sortable: true, value: 'description' },
        { text: 'Autor', align: 'start', sortable: true, value: 'author' },
        { text: 'Hochgeladen', align: 'end', sortable: true, value: 'datetime' }
      ],
      addProjectUserDialog: false,
      addProjectUserDialogUser: null,
      addProjectUserDialogRole: null,
      removeProjectUserDialog: false,
      removeProjectUserDialogUser: null,
      addProjectSurveyDialog: false,
      addProjectSurveyDialogSurvey: null,
      addProjectSurveyDialogName: '',
      addProjectSurveyDialogRole: null,
      removeProjectSurveyDialog: false,
      removeProjectSurveyDialogSurvey: null,
      addSurveySubmissionDialog: false,
      addSurveySubmissionDialogSurvey: null,
      addSurveySubmissionDialogSurveyDescription: '',

      unterlagenHeaders: [
        { text: 'Dateiname', align: 'start', sortable: true, value: 'filename' },
        { text: 'Hochgeladen', align: 'end', sortable: true, value: 'datetime' }
      ],
      unterlagen: [
        { filename: 'Baustellenplan.pdf', datetime: '2022-01-01 10:00' }
      ],
    }
  },

  computed: {
    teamHeaders: function() {
      const isAdmin = this.isAdmin
      return isAdmin ? this.teamHeadersAdmin : this.teamHeadersUser
    },
    
    surveysHeaders: function() {
      const isAdmin = this.isAdmin
      return isAdmin ? this.surveysHeadersAdmin : this.surveysHeadersUser
    },

    project: function() {
      return this.projects.find(e => e.id == this.$route.params.id) || { 'name': '' };
    },
    // TODO: CRUD role requirements
    // TODO: CRUD project roles
    projectUserRoles: function() {
      return this.rolesByProjectId(this.project.id)
    },

    projectRoleRequirements: function() {
      return this.roleRequirementsByProjectId(this.project.id);
    },

    allUsersFormatted() {
      const us = this.allUsers
      return us.map(function(u) {
        return { id: u.id, text: u.name + ' ' + u.firstName }
      }).sort((a, b) => (a.text > b.text) ? 1 : -1)
    },

    allSurveysFormatted() {
      const ts = this.surveys
      return ts.map(function(t) {
        return { id: t.id, text: t.name }
      }).sort((a, b) => (a.text > b.text) ? 1 : -1)
    },

    ...mapState(ProjectsPath, [ 'projects' ]),

    ...mapState(SurveysPath, [ 'surveys' ]),

    ...mapGetters(UsersPath, { allUsers: UsersGetters.USERS }),

    ...mapGetters(AuthPath, {
      isAdmin: AuthGetters.IS_ADMIN,
      userId: AuthGetters.USERID
    }),

    ...mapGetters(ProjectsPath, {
      usersByProjectId: ProjectsGetters.USERS_BY_PROJECT_ID,
      roleRequirementsByProjectId: ProjectsGetters.ROLEREQUIREMENTS_BY_PROJECT_ID,
      rolesByProjectId: ProjectsGetters.ROLES_BY_PROJECT_ID
    }),

    ...mapGetters(SurveysPath, {
      submissionsByPrrs: SurveysGetters.SUBMISSIONS_BY_PRRS
    })
  },

  methods: {
    call: function(phone) {
      window.open('tel:' + phone, '_self');
    },

    email: function(email) {
      window.open('mailto:' + email, '_self');
    },

    addProjectUserDialogSave: function() {
      if (this.isAdmin) {
        const projectUser = {
          projectId: this.project.id,
          userId: this.addProjectUserDialogUser.id,
          roleId: this.addProjectUserDialogRole.id
        }
        this[ProjectsActions.ADD_PROJECT_USER](projectUser)
        .then(() => {
          this[Actions.NOTIFICATION_SHOW_INFO]('Mitarbeiter hinzugefügt')
        })
        .catch(() => {
          this[Actions.NOTIFICATION_SHOW_ERROR]('Mitarbeiter nicht hinzugefügt')
        })
      }
      this.addProjectUserDialog = false
      this.addProjectUserDialogUser = null
      this.addProjectUserDialogRole = null
    },

    removeProjectUserDialogSave: function() {
      if (this.isAdmin && this.removeProjectUserDialogUser) {
        this[ProjectsActions.REMOVE_PROJECT_USER](this.removeProjectUserDialogUser.projectUserId)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Mitarbeiter entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Mitarbeiter nicht entfernt'))
      }
      this.removeProjectUserDialog = false
      this.removeProjectUserDialogUser = null
    },

    addProjectSurveyDialogSave: function() {
      if (this.isAdmin) {
        const projectSurvey = {
          projectId: this.project.id,
          surveyId: this.addProjectSurveyDialogSurvey.id,
          roleId: 1, // TODO
          name: this.addProjectSurveyDialogName
        }
        this[ProjectsActions.ADD_PROJECT_ROLE_REQUIREMENT](projectSurvey)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Schulung hinzugefügt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Schulung nicht hinzugefügt'))
      }
      this.addProjectSurveyDialog = false
      this.addProjectSurveyDialogSurvey = null
      this.addProjectSurveyDialogName = ''
      this.addProjectSurveyDialogRole = null
    },

    removeProjectSurveyDialogSave: function() {
      if (this.isAdmin && this.removeProjectSurveyDialogSurvey) {
        this[SurveysActions.REMOVE_SUBMISSION](this.removeProjectSurveyDialogSurvey.id)
        .then(() => this[Actions.NOTIFICATION_SHOW_INFO]('Eintrag entfernt'))
        .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Eintrag nicht entfernt'))
      }
      this.removeProjectSurveyDialog = false
      this.removeProjectSurveyDialogSurvey = null
    },

    addSurveySubmissionDialogSave: function() {
      const submission = {
        surveyId: this.addSurveySubmissionDialogSurvey.surveyId,
        userId: this.userId,
        description: this.addSurveySubmissionDialogSurveyDescription
      }
      console.log(submission)
      this[SurveysActions.ADD_SUBMISSION](submission)
      .then(submission => {
        this.addSurveySubmissionDialogSurveyDescription = ''
        this.$router.push(`/surveys/submissions/${submission.id}?edit`)
      })
      .catch(() => this[Actions.NOTIFICATION_SHOW_ERROR]('Eintrag nicht erstellt'))
    },

    clickSubmission: function(submission) {
      this.$router.push(`/surveys/submissions/${submission.id}`)
    },

    ...mapActions(AuthPath, [AuthActions.REFRESH]),

    ...mapActions(UsersPath, [ UsersActions.REFRESH ]),

    ...mapActions(SurveysPath, [
      SurveysActions.REFRESH,
      SurveysActions.REFRESH_SUBMISSIONS,
      SurveysActions.ADD_SUBMISSION,
      SurveysActions.REMOVE_SUBMISSION
    ]),

    ...mapActions(ProjectsPath, [
      ProjectsActions.REFRESH,
      ProjectsActions.REFRESH_USERS,
      ProjectsActions.REFRESH_ROLEREQUIREMENTS,
      ProjectsActions.ADD_PROJECT_USER,
      ProjectsActions.REMOVE_PROJECT_USER,
      ProjectsActions.ADD_PROJECT_ROLE_REQUIREMENT,
      ProjectsActions.REMOVE_PROJECT_ROLE_REQUIREMENT
    ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_INFO,
      Actions.NOTIFICATION_SHOW_ERROR
    ])
  }
}
</script>
