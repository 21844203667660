<template>
  <div>
    <v-card-title>{{ survey.name }}</v-card-title>
    <v-card-subtitle align="left">{{ survey.description }}</v-card-subtitle>
    
    <v-card-text
      class="mx-4"
      label="Inhalt"
      rows="4"
    ></v-card-text>
    
    <v-card
      v-for="item in sort(questionsBySurveyId(survey.id))"
      :key="item.id"
      class="px-0"
    >
      <v-card-text align="left" style="color: black"><span style="font-weight: bold">{{ item.text }}</span><br/>{{ item.description }}</v-card-text>

      <v-textarea v-if="item.questionType === 'textInput'"
        class="mx-3 my-0 py-0"
        rows="3"
        :readonly="!editMode"
      >{{ optionsByQuestionId(item.id)[0] }}</v-textarea>

      <v-radio-group v-else-if="item.questionType === 'singleChoice'"
        class="mx-3 my-0 py-0">
        <v-radio
          v-for="option in sort(optionsByQuestionId(item.id))"
          :key="option.id"
          :label="option.text"
          :value="option.id"
          :readonly="!editMode"
        ></v-radio>
      </v-radio-group>

      <v-card-text v-else-if="item.questionType === 'signature'">
        <VueSignaturePad width="300px" height="100px" style="border: dashed 1px"/>
      </v-card-text>

      <v-card-text v-else-if="item.questionType !== 'text'">Fragetyp "{{ item.questionType }}" nicht unterst&uuml;tzt!</v-card-text>
    </v-card>

    <v-card-actions>
      <v-btn v-if="editMode" @click="$router.go(-1)">L&ouml;schen</v-btn>
      <v-btn v-else @click="$router.go(-1)">Zur&uuml;ck</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="editMode" color="primary">Abschicken</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Actions, AuthActions, AuthGetters, AuthPath, SurveysActions, SurveysGetters, SurveysPath } from '../store/definitions';
// @ is an alias to /src

export default {
  name: 'SurveySubmission',
  
  components: {
  },

  mounted: function() {
    this[AuthActions.REFRESH]()
    this[SurveysActions.REFRESH]()
    this[SurveysActions.REFRESH_QUESTIONS]()
    this[SurveysActions.REFRESH_OPTIONS]()
    this[SurveysActions.REFRESH_SUBMISSIONS]()
  },

  data: () => {
    return {
      search: ''
    }
  },

  computed: {
    submission: function() {
      return this.submissionById(this.$route.params.id)
    },

    editMode: function() {
      return 'edit' in  this.$route.query
    },

    survey: function() {
      if (this.submission === undefined) {
        return { id: -1, name: '', description: ''}
      }
      let survey = this.surveyById(this.submission.surveyId)
      return survey
    },
    
    ...mapGetters(SurveysPath, {
      surveyById: SurveysGetters.SURVEY_BY_ID,
      submissionById: SurveysGetters.SUBMISSION_BY_ID,
      questionsBySurveyId: SurveysGetters.QUESTIONS_BY_SURVEY_ID,
      optionsByQuestionId: SurveysGetters.OPTIONS_BY_QUESTION_ID
    }),

    ...mapGetters(AuthPath, {
      isAdmin: AuthGetters.IS_ADMIN,
      userId: AuthGetters.USERID
    }),
  },

  methods: {
    sort: function(array) {
      return array.slice().sort((a, b) => a.position - b.position);
    },

    ...mapActions(SurveysPath, [
      SurveysActions.REFRESH,
      SurveysActions.REFRESH_QUESTIONS,
      SurveysActions.REFRESH_OPTIONS,
      SurveysActions.REFRESH_SUBMISSIONS
    ]),

    ...mapActions(AuthPath, [ AuthActions.REFRESH ]),

    ...mapActions([
      Actions.NOTIFICATION_SHOW_ERROR,
      Actions.NOTIFICATION_SHOW_INFO
    ])
  }
}
</script>
